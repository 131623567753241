import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { NgIconsModule, provideNgIconsConfig } from '@ng-icons/core';
import * as feather from '@ng-icons/feather-icons';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoadingComponent } from './components/loading/loading.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingInterceptor } from '../shared/interceptors/loading.interceptor';
import { CalculatorComponent } from './components/calculator/calculator.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';


@NgModule({
  declarations: [
    LoadingComponent, 
    CalculatorComponent,
    ConfirmModalComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatIconModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgIconsModule.withIcons({ ...feather }),
    ModalModule.forRoot(),
  ],
  exports: [
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatIconModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgIconsModule,
    LoadingComponent,
    CalculatorComponent,
    ConfirmModalComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    provideNgIconsConfig({
      size: '2em',
      color: 'black',
    }),
  ],
})
export class GeneralModule { }
