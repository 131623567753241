import { Component, DestroyRef, OnInit, TemplateRef, inject } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UsersService } from 'src/app/services/users/users.service';
import { UserInterface, UsersListResponse } from 'src/app/services/users/interface/user.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { mergeMap, tap } from 'rxjs';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})

export class UserManagementComponent implements OnInit {
  users: UserInterface[] = [];
  selectedUser: UserInterface | null = null;
  itemsPerPage = 10;
  currentPage = 1;
  displayedUsers: UserInterface[] = [];
  userModalRef?: BsModalRef;
  confirmDeleteModalRef?: BsModalRef;
  resetPasswordModalRef?: BsModalRef;
  userId: number;
  userToEdit: UserInterface | null = null;

  private destroyRef = inject(DestroyRef);

  get totalPages(): number {
    return Math.ceil(this.users.length / this.itemsPerPage);
  }
  
  constructor(private modalService: BsModalService,
              private usersService: UsersService) {}

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    this.usersService.getUsers().pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((res: UsersListResponse) => {
      this.users = res.data;
      this.displayedUsers = this.getUsersForPage(this.currentPage);
    })
  }

  openNewUserModal(template: TemplateRef<any>) {
    this.userModalRef = this.modalService.show(template, Object.assign({}, { class: 'new-user-modal' }));
  }

  openConfirmDeleteModal(template: TemplateRef<any>, userId: number) {
    this.userId = userId;
    this.confirmDeleteModalRef = this.modalService.show(template, Object.assign({}, { class: 'confirm-delete-modal' }));
  }

  openResetPasswordModal(template: TemplateRef<any>, user: UserInterface) {
    this.selectedUser = user;
    this.resetPasswordModalRef = this.modalService.show(template, Object.assign({}, { class: 'reset-password-modal' }));
  }

  onDeleteUser(userId: number) {
    this.usersService.deleteUser(userId).pipe(
      tap(() => this.confirmDeleteModalRef?.hide()),
      mergeMap(() => this.usersService.getUsers()),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((res: UsersListResponse) => {
      this.users = res.data;
      this.displayedUsers = this.getUsersForPage(this.currentPage);
    })
    
    this.displayedUsers = this.getUsersForPage(this.currentPage);
    if (this.displayedUsers.length === 0 && this.currentPage > 1) {
      this.onPageChange(this.currentPage - 1);
    }
  }

  getUsersForPage(page: number): any[] {
    const startIndex = (page - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.users.slice(startIndex, endIndex);
  }

  onPageChange(page: number): void {
    if (page >= 1) {
      this.currentPage = page;
      this.displayedUsers = this.getUsersForPage(page);
    }
  }

  getPages(): number[] {
    const totalPages = Math.ceil(this.users.length / this.itemsPerPage);
    return Array(totalPages).fill(0).map((_, index) => index + 1);
  }

  onUserUpdatedOrCreated() {
    this.userToEdit = null;
    this.getUsers();
  }

  userRoleName(user_role_id: number): string {
    switch (user_role_id) {
      case 1:
        return 'Super Admin';
      case 2:
        return 'Admin';
      case 3:
        return 'User';
      case 4:
        return 'Design consultant';
      default:
        return '';
    }
  }

  openEditUserModal(user: UserInterface, template: TemplateRef<any>) {
    this.userToEdit = user;
    this.userModalRef = this.modalService.show(template, Object.assign({}, { class: 'new-user-modal' }));
  }
}
