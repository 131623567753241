import { HttpHeaders } from '@angular/common/http';

export const headers = () => {

  const headers: any = {
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    "X-Requested-With": '*',
    'Cache-Control': 'no-cache',
    'Access-Control-Allow-Headers': 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,x-requested-with',
    'Access-Control-Expose-Headers': '*',
    'Authorization': 'Bearer ' + localStorage.getItem('token'),
  };

  return new HttpHeaders(headers);
};

export const fileHeaders = () => {

  const headers: any = {
    'Authorization': 'Bearer ' + localStorage.getItem('token'),
  };

  return new HttpHeaders(headers);
};