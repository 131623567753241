import { AfterViewChecked, Component, Input } from '@angular/core';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements AfterViewChecked {
  @Input() isCalcVisible: boolean;
  displayValue: string = '';

  ngAfterViewChecked() {
    const inputElement = document.querySelector('.display') as HTMLInputElement;
    if (inputElement && this.isCalcVisible) {
      inputElement.focus();
    }
  }
  
  appendToDisplay(value: string) {
    if (this.displayValue === '' && value !== '.') {
      this.displayValue = value;
    } else {
      this.displayValue += value;
    }
  }

  calculate() {
    try {
      let result = eval(this.displayValue);
      this.displayValue = parseFloat(result.toFixed(10)).toString(); 
    } catch (error) {
      this.displayValue = 'Error';
    }
  }

  clearDisplay() {
    this.displayValue = '';
  }

  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.calculate();
    }
  }
  
}
