import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, combineLatest, map, mergeMap, tap } from "rxjs";
import { LoginResponse, UserInfo, UserInfoResponse } from "./interface/auth.interface";
import { headers } from "src/app/general/helpers/headers.helper";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  public isLoggedIn: Observable<boolean | unknown>;
  url = environment.APP_ENDPOINT;
  _token: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  _user: BehaviorSubject<UserInfo | null> = new BehaviorSubject<UserInfo | null>(null);
  

  constructor(private http: HttpClient) {
    this.isLoggedIn = combineLatest([this.token]).pipe(map(([token]) => token));
  }

  init(): void {    
    if (localStorage.getItem('token') && localStorage.getItem('user')) {
      let token = localStorage.getItem('token');
      let user = JSON.parse(localStorage.getItem('user')!);

      if (token) {
        this.setToken(token);
        this.setUser(user);
        return
      } else {
        this.setToken(null!);
        this.setUser(null!);
      }
    }
  }

  login(body: {password: string, email: string}): Observable<UserInfoResponse> {
    return this.http.post<LoginResponse>(this.url + 'login', body).pipe(
      tap((res: LoginResponse) => this.setToken(res.token)),
      mergeMap(() => this.getUserInfo())
    )
  }

  getUserInfo(): Observable<UserInfoResponse> {
    return this.http.get<UserInfoResponse>(this.url + 'user/get-info', {headers: headers()}).pipe(
      tap((res: UserInfoResponse) => this.setUser(res.user))
    )
  }

  setToken(token: string): void {
    token ? localStorage.setItem('token', token) : localStorage.removeItem('token');
    this._token.next(token);
  }

  setUser(user: UserInfo): void {
    user ? localStorage.setItem('user', JSON.stringify(user)) : localStorage.removeItem('user');
    this._user.next(user);
  }

  get token(): Observable<string | null>{
    return this._token.asObservable();
  }

  get user(): Observable<UserInfo | null>{
    return this._user.asObservable();
  }

  logout(): Observable<{error?: string, message: string}> {
    return this.http.post<{error?: string, message: string}>(this.url + 'logout', {token: localStorage.getItem('token')}).pipe(
      tap(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        this.setToken(null!);
      })
    );
  }
}