<div class="calculator" *ngIf="isCalcVisible">
    <input class="display" 
            [(ngModel)]="displayValue" 
            type="text" 
            (keypress)="onKeyPress($event)"
            maxlength="30">
    <div class="buttons buttons--margin-bottom">
      <button (click)="clearDisplay()">AC</button>
    </div>
    <div class="buttons">
      <button (click)="appendToDisplay('7')">7</button>
      <button (click)="appendToDisplay('8')">8</button>
      <button (click)="appendToDisplay('9')">9</button>
      <button (click)="appendToDisplay('/')">/</button>
      <button (click)="appendToDisplay('4')">4</button>
      <button (click)="appendToDisplay('5')">5</button>
      <button (click)="appendToDisplay('6')">6</button>
      <button (click)="appendToDisplay('*')">*</button>
      <button (click)="appendToDisplay('1')">1</button>
      <button (click)="appendToDisplay('2')">2</button>
      <button (click)="appendToDisplay('3')">3</button>
      <button (click)="appendToDisplay('-')">-</button>
      <button (click)="appendToDisplay('0')">0</button>
      <button (click)="appendToDisplay('.')">.</button>
      <button (click)="calculate()">=</button>
      <button (click)="appendToDisplay('+')">+</button>
    </div>
  </div>
  