import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  isLoading$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  setLoaderValue(value: boolean): void {
    this.isLoading$.next(value);
  }

  isLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }
}
