import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate {

  constructor(private router: Router,
              private authService: AuthService) {}

  canActivate(): Observable<boolean> | boolean {
    let value!: boolean;
    this.authService.isLoggedIn.subscribe(res => {
      if (res) {
        value = true
      } else {
        value = false;
        this.router.navigate(['auth']);
      }
    })

    return value;
  }
}
