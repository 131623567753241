import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { LoadingService } from './services/loading/loading.service';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { UserInfo } from './services/auth/interface/auth.interface';
import { combineLatest, delay, filter } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isCalcVisible: boolean = false;
  isLoading: boolean = false;
  token: string | null = null;
  user: UserInfo | null = null;
  urlAfterRedirects: string = '';
  environment = environment;

  constructor(private authService: AuthService,
              private loader: LoadingService,
              private router: Router,
              private route: ActivatedRoute) {
    this.authService.init();
    this.tokenAndUserSubscription();
    this.checkUrl();
  }

  ngOnInit(): void {
    this.loader.isLoading().pipe(delay(0)).subscribe((value: boolean) => {
      this.isLoading = value;
    })
  }

  tokenAndUserSubscription(): void {
    combineLatest([this.authService.token, this.authService.user ]).subscribe(([token, user]) => {
      this.token = token;
      this.user = user;
    })
  }

  logout(): void {
    this.authService.logout().subscribe();
  }

  navigateTo(route: string): void {
    this.router.navigate([route]);
  }

  isAdminPanel(): boolean {
    return this.router.url.includes('admin');
  }
  
  toggleCalcVisible(): void {
    this.isCalcVisible = !this.isCalcVisible;
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(event: TouchEvent): void {
    event.preventDefault(); 
  }
  
  private checkUrl(): void {
    this.router.events.pipe(
      filter((e: Event) => e instanceof NavigationEnd)
    ).subscribe((res: Event) => {
      this.urlAfterRedirects = (res as NavigationEnd).urlAfterRedirects;
    })
  }
}
