import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-delete-modal',
  templateUrl: './confirm-delete-modal.component.html',
  styleUrls: ['./confirm-delete-modal.component.scss']
})
export class ConfirmDeleteModalComponent {
  @Input() userId: number;
  @Output() closeModal = new EventEmitter<boolean>(false);
  @Output() deleteUser = new EventEmitter<number>();
}
