import { Component, EventEmitter, Output, DestroyRef, inject, ViewEncapsulation, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { concatMap, filter } from 'rxjs';
import { UserInterface } from 'src/app/services/users/interface/user.interface';
import { UsersService } from 'src/app/services/users/users.service';
import { passwordMatchValidator } from '../../validators/validators';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserModalComponent implements OnInit {
  @Input() userToEdit: UserInterface | null = null;
  @Output() closeModal = new EventEmitter<boolean>(false);
  @Output() userCreatedOrUpdated = new EventEmitter<void>();
  userForm: FormGroup;
  roles: {role_id: number, role_name: string}[] = [
    {role_id: 1, role_name: 'Super Admin'},
    {role_id: 2, role_name: 'Admin'},
    {role_id: 3, role_name: 'User'}
  ];
  submitted: boolean;
  private destroyRef = inject(DestroyRef);

  constructor(private fb: FormBuilder,
              private usersService: UsersService) {}

  ngOnInit(): void {
    this.buildForm();
    this.passwordValueChangesSubscription();
  }

  buildForm() {
    this.userForm = this.fb.group({
      name: [this.userToEdit ? this.userToEdit.name : null, Validators.required],
      email: [this.userToEdit ? this.userToEdit.email : null, [Validators.required, Validators.email]],
      password: [null, this.userToEdit ? [Validators.minLength(8), Validators.maxLength(64)] : 
                                         [Validators.required, Validators.minLength(8), Validators.maxLength(64)]],
      confirm_password: [null, this.userToEdit ? []  : [Validators.required]],
      role_id: [this.userToEdit ? this.userToEdit.role_id : null, Validators.required],
      phone: [this.userToEdit ? this.userToEdit.phone : null, Validators.required]
    }, { validator: passwordMatchValidator })
  }

  private passwordValueChangesSubscription(): void {
    this.userForm.get('password')?.valueChanges.pipe(
      filter(() => !!this.userToEdit),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((value: string) => {
      if (!value) {
        this.userForm.get('confirm_password')?.setValidators([]);
        this.userForm.validator = null;
      } else {
        this.userForm.get('confirm_password')?.setValidators([Validators.required]);
        this.userForm.validator = passwordMatchValidator;
      }
      this.userForm.updateValueAndValidity({ emitEvent: false });
    })
  }

  createUser(): void {
    this.submitted = true;

    if (this.userForm.invalid) return
    
    this.usersService.createUser(this.userForm.value).pipe(
      concatMap(() => this.usersService.getUsers()),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: () => {
        this.userToEdit = null;
        this.userCreatedOrUpdated.emit();
      },
      complete: () => {
        this.userToEdit = null;
        this.closeModal.emit(true);
        this.submitted = false;
      }
    });
  }

  updateUser(): void {
    this.submitted = true;

    if (this.userForm.invalid) return

    let userForUpdate = this.userForm.value;
    if (!this.userForm.get('password')?.value) {
      delete userForUpdate['password'];
      delete userForUpdate['confirm_password'];
    }
    
    this.usersService.updateUser(this.userForm.value, this.userToEdit?.id!).pipe(
      concatMap(() => this.usersService.getUsers()),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: () => {
        this.userToEdit = null;
        this.userCreatedOrUpdated.emit();
      },
      complete: () => {
        this.userToEdit = null;
        this.closeModal.emit(true);
        this.submitted = false;
      }
    });
  }
}
