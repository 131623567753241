import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from 'src/app/services/users/interface/user.interface';

@Injectable({
  providedIn: 'root'
})

export class AdminGuardService implements CanActivate {

  constructor() {}

  canActivate(): Observable<boolean> | boolean {
    let value!: boolean;
    const user: User | null = JSON.parse(localStorage.getItem('user')!) || null;

    return !!(user!.role_id && user!.role_id === 1);
  }
}
