import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LoggedGuardService {
  constructor(private router: Router) {}

  canActivate(): Observable<boolean> | boolean {
    let value!: boolean;
    let token = localStorage.getItem('token');

    if (token) {
      value = false;
      this.router.navigate(['order/list']);        
    } else {
      value = true;
    }

    return value;
  }
}
