<div class="confirm-delete">
    <div class="confirm-delete__header modal-header">
        <h2 class="modal-title pull-left mb-0">Confirm delete, please.</h2>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal.emit(true)">
          <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="confirm-delete__descrition-container modal-body">
        <div class="confirm-delete__description">Do you want to delete this user?</div>
    </div>
    <div class="confirm-delete__footer modal-footer justify-content-between">
        <button type="button" class="confirm-delete__btn" (click)="closeModal.emit(true)">No</button>
        <button type="submit" class="confirm-delete__btn confirm-delete__btn--delete" (click)="deleteUser.emit(userId)">Yes</button>
    </div>
</div>