import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoggedGuardService } from './general/guards/logged.guard';
import { AuthGuardService } from './general/guards/auth.guard';
import { SuperAdminRoutingModule } from './super-admin/super-admin-routing.module';
import { GeneralModule } from './general/general.module';
import { AngularDraggableModule } from 'angular2-draggable';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    }),
    SuperAdminRoutingModule,
    GeneralModule,
    AngularDraggableModule
  ],
  bootstrap: [AppComponent],
  providers: [
    LoggedGuardService,
    AuthGuardService
  ],
})
export class AppModule { }
