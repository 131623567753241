import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { headers } from 'src/app/general/helpers/headers.helper';
import { User, UserResponse, UsersListResponse } from './interface/user.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  url = environment.APP_ENDPOINT;

  constructor(private http: HttpClient) { }

  getUsers(): Observable<UsersListResponse> {
    return this.http.get<UsersListResponse>(this.url + 'users/list', { headers: headers() })
  }

  createUser(body: User): Observable<UserResponse> {
    return this.http.post<UserResponse>(this.url + 'registration', body, { headers: headers() })
  }

  updateUser(body: User, id: number): Observable<UserResponse> {
    return this.http.post<UserResponse>(this.url + `user/update/${id}`, body, {headers: headers()});
  }

  deleteUser(id: number): Observable<UserResponse> {
    return this.http.delete<UserResponse>(this.url + `user/delete/${id}`, {headers: headers()});
  }
}
