<div class="confirm">
  <div class="confirm__header modal-header">
      <h2 class="modal-title pull-left mb-0">{{ modalTitle }}</h2>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal.emit(true)">
        <span aria-hidden="true">×</span>
      </button>
  </div>
  <div class="confirm__descrition-container modal-body">
      <div class="confirm__description">{{ confirmationText }}</div>
  </div>
  <div class="confirm__footer modal-footer justify-content-between">
      <button type="button" class="confirm__btn" (click)="closeModal.emit(true)">No</button>
      <button type="submit" class="confirm__btn confirm__btn--delete" (click)="confirm.emit(true)">Yes</button>
  </div>
</div>
