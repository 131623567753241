import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { EMPTY, Observable, catchError, finalize, throwError } from 'rxjs';
import { LoadingService } from '../../services/loading/loading.service';
import { Router } from '@angular/router';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  constructor(private loadingService: LoadingService,
              private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loadingService.setLoaderValue(true);
    return next.handle(request).pipe(
      finalize(() => this.loadingService.setLoaderValue(false)),
      catchError((requestError: HttpErrorResponse) => {
        
        if (requestError.error.status === 'Token is Expired' && requestError.status === 403) {
          localStorage.removeItem('token');
          localStorage.removeItem('username');
          this.router.navigate(['/']);
          return EMPTY
        }

        return throwError(() => requestError);
      })
    );
  }
}
