<app-loading *ngIf="isLoading"></app-loading>
<ng-container *ngIf="token && user">
  <div class="header-container">
    <div class="header">
      <ul class="flex w-50">
        <li class="flex-1 mr-2">
          <a class="text-center block border rounded py-2 px-2 whitespace-nowrap text-zinc-900 hover:bg-gray-300 cursor-pointer"
             [class.bg-gray-300]="urlAfterRedirects.includes('order')" (click)="navigateTo('order')">
            Orders List
          </a>
        </li>
        <ng-container *ngIf="!environment.production">
          <li class="flex-1 mr-2">
            <a class="text-center block border rounded py-2 px-2 whitespace-nowrap text-zinc-900 hover:bg-gray-300 cursor-pointer"
               [class.bg-gray-300]="urlAfterRedirects.includes('invoices')" (click)="navigateTo('invoices')">
              Invoices
            </a>
          </li>
        </ng-container>
        <ng-container *ngIf="+user.role_id === 1">
          <li class="flex-1 mr-2">
            <a class="text-center block border rounded py-2 px-2 whitespace-nowrap text-zinc-900 hover:bg-gray-300 cursor-pointer"
               [class.bg-gray-300]="urlAfterRedirects.includes('admin/users')" (click)="navigateTo('admin')">
              Manage Users
            </a>
          </li>
        </ng-container>
      </ul>
      <div id="myCalc">
        <div class="header__calc-btn" id="myCalcHeader" (click)="toggleCalcVisible()">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-9 h-9">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V13.5Zm0 2.25h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V18Zm2.498-6.75h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V13.5Zm0 2.25h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V18Zm2.504-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5Zm0 2.25h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V18Zm2.498-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5ZM8.25 6h7.5v2.25h-7.5V6ZM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0 0 12 2.25Z" />
          </svg>    
        </div>
      </div>
      <div class="header__logout" (click)="logout()">
        <ng-icon name="featherLogOut"></ng-icon>
      </div>
    </div>
  </div>
</ng-container>
<router-outlet></router-outlet>
<app-calculator [isCalcVisible]="isCalcVisible" ngDraggable touch-action="none"></app-calculator>