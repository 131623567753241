<div class="user-management">
    <div class="user-management__head">
        <div class="user-management__title">
            User Management
        </div>
        <button class="user-management__new-user-button"  (click)="openNewUserModal(userTemplate)">New User</button>
    </div>
    <div class="user-list">
        <div class="user-list__header">
            <div class="user-list__header-name">Name</div>
            <div class="user-list__header-login">Email</div>
            <div class="user-list__header-phone">Phone</div>
            <div class="user-list__header-role">Role</div>
            <div class="user-list__header-actions">Actions</div>
        </div>

        <ng-container *ngFor="let user of displayedUsers; let i = index;">
            <div class="user-list__item-container">
                <div class="user-list__item-name">{{user.name}}</div>
                <div class="user-list__item-login">{{user.email}}</div>
                <div class="user-list__item-phone">{{user.phone}}</div>
                <div class="user-list__item-role">{{ userRoleName(user.role_id) }}</div>
                <div class="user-list__actions">
                    <div class="user-list__item-edit" (click)="openEditUserModal(user, userTemplate)">Edit</div>
                    <div class="user-list__item-delete ms-2" (click)="openConfirmDeleteModal(confirmDeleteTemplate, user.id)">Delete</div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="user-management__pagination pagination">
        <button class="pagination__prev-btn" (click)="onPageChange(currentPage - 1)" 
                [disabled]="currentPage === 1">&#10148;</button>
        <ng-container *ngFor="let page of getPages()">
            <div class="pagination__page-number-container">
                <button class="pagination__page-item-btn" (click)="onPageChange(page)" [class.active]="page === currentPage">{{page}}</button>
              </div>
        </ng-container>
        <button class="pagination__next-btn" (click)="onPageChange(currentPage + 1)" 
                [disabled]="displayedUsers.length < itemsPerPage || currentPage === totalPages">&#10148;</button>
    </div>
</div>

<ng-template #userTemplate>
    <app-user-modal (closeModal)="userModalRef?.hide()" 
                    (userCreatedOrUpdated)="onUserUpdatedOrCreated()"
                    [userToEdit]="userToEdit"></app-user-modal>
</ng-template>

<ng-template #confirmDeleteTemplate>
    <app-confirm-delete-modal (closeModal)="userToEdit = null; confirmDeleteModalRef?.hide()" 
                              (deleteUser)="onDeleteUser(userId)" 
                              [userId]="userId"></app-confirm-delete-modal>
</ng-template>