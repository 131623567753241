<div class="new-user">
    <div class="new-user__header modal-header">
        <h2 class="modal-title pull-left mb-0">Create new user</h2>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal.emit(true)">
          <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="new-user__body modal-body">
        <form [formGroup]="userForm" class="new-user__form">
            <div class="new-user__input-container">
                <div class="new-user__block-title">Name</div>
                <input type="text" 
                       class="new-user__input" 
                       formControlName="name" 
                       [class.new-user__input--error]="submitted && userForm.get('name')?.invalid"/>      
            </div>
            <div class="new-user__input-container">
                <div class="new-user__block-title">Email</div>
                <input type="text" 
                       class="new-user__input" 
                       formControlName="email" 
                       [class.new-user__input--error]="submitted && userForm.get('email')?.invalid"/>      
            </div>
            <div class="new-user__input-container">
                <div class="new-user__block-title">Password</div>
                <input type="password" 
                        class="new-user__input" 
                        formControlName="password" 
                        [class.new-user__input--error]="submitted && userForm.get('password')?.invalid"/>      
            </div>
            <div class="new-user__input-container">
                <div class="new-user__block-title">Confirm Password</div>
                <input type="password" 
                        class="new-user__input" 
                        formControlName="confirm_password" 
                        [class.new-user__input--error]="submitted && userForm.get('confirm_password')?.invalid"/>      
            </div>
            <div class="new-user__input-container">
                <div class="new-user__block-title">Role ID</div>
                <ng-select formControlName="role_id" class="new-user__select" bindValue="role_id"
                           [ngClass]="{'error': submitted && userForm.get('role_id')?.invalid}" [items]="roles">
                    <ng-template ng-label-tmp let-item="item">{{ item.role_id + ' - ' + item.role_name }}</ng-template>
                    <ng-template ng-option-tmp let-item="item">{{ item.role_id + ' - ' + item.role_name }}</ng-template>
                </ng-select>
            </div>
            <div class="new-user__input-container">
                <div class="new-user__block-title">Phone</div>
                <input type="text" 
                      class="new-user__input" 
                      formControlName="phone" 
                      [class.new-user__input--error]="submitted && userForm.get('phone')?.invalid"/>      
            </div>
        </form>
    </div>
    <div class="new-user__footer modal-footer justify-content-between">
        <button type="button" class="new-user__btn" (click)="closeModal.emit(true)">Close</button>
        <ng-container *ngIf="!userToEdit">
            <button type="submit" class="new-user__btn new-user__btn--create" (click)="createUser()">Create</button>
        </ng-container>
        <ng-container *ngIf="userToEdit">
            <button type="submit" class="new-user__btn new-user__btn--create" (click)="updateUser()">Update</button>
        </ng-container>
    </div>
</div>