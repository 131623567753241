import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
  @Output() closeModal = new EventEmitter<boolean>(false);
  @Output() confirm = new EventEmitter<boolean>(false);
  @Input() modalTitle: string;
  @Input() confirmationText: string;
}
